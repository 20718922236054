import { Link } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>404 Not Found</h1>
    <p>
      Ooops! You just hit a route that doesn&#39;t exist... the sadness. Check
      the url for any typos or go back to the{" "}
      <Link to="/" className="font-medium text-gray-900 dark:text-white">
        main page
      </Link>
    </p>
  </Layout>
)

export default NotFoundPage
